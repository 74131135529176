<template>
	<div
		class="full-height flex-column"
	>
		<Search
			:option="{ not_search: true}"
			:search="item_search"
			@click="getData"
		></Search>
		<div class="full-height justify-space-between gap-10">

			<div class="flex-1 ">

				<div class="bg-white pa-10">
					<h6>구매 가능 시간</h6>
					<div>
						<select
							v-model="item.sh"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 24"
								:key="'start_h_' + i"
								:value="('0' + (i - 1)).slice(-2)"
							>{{ ('0' + (i - 1)).slice(-2)}} 시</option>
						</select>
						<select
							v-model="item.sm"
							class="input-box-inline"
						>
							<option
								v-for="i in 6"
								:key="'start_m_' + i"
								:value="(i - 1) + '0'"
							>{{ (i - 1) + '0' }} 분</option>
						</select>
						~
						<select
							v-model="item.eh"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 24"
								:key="'end_h_' + i"
								:value="('0' + (i - 1)).slice(-2)"
							>{{ ('0' + (i - 1)).slice(-2)}} 시</option>
						</select>
						<select
							v-model="item.em"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 6"
								:key="'start_m_' + i"
								:value="(i - 1) + '0'"
							>{{ (i - 1) + '0' }} 분</option>
						</select>

						<button
							class="btn-inline btn-primary "
							@click="postSaveTime"
						>등록</button>
					</div>


					<h6 class="mt-30">구매 가능 시간 목록</h6>
					<div
						v-for="(time, t_index) in items_time"
						:key="'time_' + t_index"
						class="mt-10"
					>
						<select
							v-model="time.sh"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 24"
								:key="'start_h_' + i"
								:value="('0' + (i - 1)).slice(-2)"
							>{{ ('0' + (i - 1)).slice(-2)}} 시</option>
						</select>
						<select
							v-model="time.sm"
							class="input-box-inline"
						>
							<option
								v-for="i in 6"
								:key="'start_m_' + i"
								:value="(i - 1) + '0'"
							>{{ (i - 1) + '0' }} 분</option>
						</select>
						~
						<select
							v-model="time.eh"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 24"
								:key="'end_h_' + i"
								:value="('0' + (i - 1)).slice(-2)"
							>{{ ('0' + (i - 1)).slice(-2)}} 시</option>
						</select>
						<select
							v-model="time.em"
							class="input-box-inline mr-10"
						>
							<option
								v-for="i in 6"
								:key="'start_m_' + i"
								:value="(i - 1) + '0'"
							>{{ (i - 1) + '0' }} 분</option>
						</select>

						<button
							class="btn-inline"
							:class="time.is_use_time == 1? 'btn-success' : 'btn-default'"
							@click="time.is_use_time = 1"
						>사용</button>
						<button
							class="btn-inline"
							:class="time.is_use_time == 1 ? 'btn-default' : 'btn-danger'"
							@click="time.is_use_time = 0"
						>미사용</button>

						<button
							class="btn-inline btn-primary ml-10"
							@click="postSaveTime(time)"
						>수정</button>

						<button
							class="btn-inline btn-danger ml-10"
							@click="postDeleteHoliday(time)"
						>삭제</button>
					</div>
				</div>

				<div class="bg-white pa-10 mt-10">
					<h6> 구매 가능 요일 </h6>
					<div>
						<button
							v-for="day in items_week"
							:key="'day_' + day.key"
							class="btn-inline"
							:class="day.is_use ? 'btn-success' : 'btn-default'"
							@click="day.is_use = !day.is_use"
						>{{ day.name }}</button>

						<button
							class="btn-inline btn-primary ml-10"
							@click="postSaveWeek"
						>등록</button>
					</div>

					<h6 class="mt-30">구매 가능 요일 목록</h6>
					<div
						v-for="(week, w_index) in items_weeks"
						:key="'week_' + w_index"
						class="mt-10"
					>
						<button
							v-for="day in week.items"
							:key="'day_' + day.key"
							class="btn-inline"
							:class="day.is_use ? 'btn-success' : 'btn-default'"
							@click="$set(day, 'is_use', !day.is_use)"
						>{{ day.name }}</button>

						<button
							class="btn-inline btn-primary ml-10"
							@click="postSaveWeek(week)"
						>수정</button>
						<button
							class="btn-inline btn-danger ml-10"
							@click="postDeleteHoliday(week)"
						>삭제</button>
					</div>
				</div>

			</div>

			<div
				class="flex-1 bg-white  full-height overflow-y-auto pa-10"
			>

				<div class="">
					<h6>구매 제한일 지정</h6>
					<div class="mt-10">

						<Date_picker
							v-model="item_holiday.sDate"
							:option="{not_prev: true, not_next: true, width: true}"
							@click="item_holiday.sDate = $event"
							width="150"
						></Date_picker>

						~

						<Date_picker
							v-model="item_holiday.eDate"
							:option="{not_prev: true, not_next: true, width: true}"
							@click="item_holiday.eDate = $event"
						></Date_picker>

						<input
							v-model="item_holiday.title" placeholder="내용"
							class="input-box-inline width-40 ml-10 mr-10"
						/>

						<button
							class="btn-inline btn-primary"
							@click="postSaveHoliday"
						>등록</button>
					</div>
				</div>

				<div class="mt-30">

					<h6>구매 제한일 목록 </h6>

					<div
						v-if="items.length > 0"
					>
						<table
							class="mt-10 table table-even"
						>
							<colgroup>
								<col width="auto" />
								<col width="auto" />
								<col width="auto" />
								<col width="auto" />
							</colgroup>
							<thead>
							<tr>
								<th>시작일</th>
								<th>종료일</th>
								<th>내용</th>
								<th>상세정보</th>
							</tr>
							</thead>
							<tbody
								v-if="items.length > 0"
							>
							<tr
								v-for="item in items"
								:key="item.uid"
							>
								<td>{{ item.sDate.slice(0, 10) }}</td>
								<td>{{ item.eDate.slice(0, 10) }}</td>
								<td>{{ item.title }}</td>
								<td>
									<button
										class="pa-5-10 btn-danger"
										@click="postDeleteHoliday(item)"
									>삭제</button>
								</td>
							</tr>
							</tbody>
						</table>

						<Pagination
							:program="program"
							:align="'center'"
							:options="item_search"

							class="mt-auto"
							@click="getSearch"
						></Pagination>
					</div>
					<Empty
						v-else
					></Empty>
				</div>
			</div>

		</div>
	</div>
</template>

<script>

import Pagination from "../../components/Pagination";
import Empty from "../Layout/Empty";
import Date_picker from "../../components/DatePicker";
import Search from "../Layout/Search";

export default {
	name: 'MypageSchedule'
	, props: ['user', 'codes', 'date', 'virtual_uid']
	, components: {Search, Date_picker, Empty, Pagination}
	, data: function(){
		return {
			program: {
				name: '구매 제한'
				, top: true
				, title: true
				, bottom: false
			}
			, item: {
				is_use_time: 1
				, is_use_week: 1
				, sDate: ''
				, eDate: ''
				, sh: '00'
				, sm: '00'
				, eh: '00'
				, em: '00'
			}
			, items_week: [
				{ key: 1, name: '월', is_use: false}
				, { key: 2, name: '화', is_use: false}
				, { key: 3, name: '수', is_use: false}
				, { key: 4, name: '목', is_use: false}
				, { key: 5, name: '금', is_use: false}
				, { key: 6, name: '토', is_use: false}
				, { key: 0, name: '일', is_use: false}
			]
			, items: []
			, item_holiday: {
				sDate: this.$date.getToday('-')
				, eDate: this.$date.getToday('-')
				, is_use: ''
				, title: ''
			}
			, items_holiday: []
			, item_search: {
				page: 1
				, list_cnt: 10
				, tCnt: 0
				, cnt: 0
				, site_info: ''
			}
			, items_time: []
			, items_weeks: []
		}
	}
	, computed: {
		use_week: function(){
			let t = ''
			let d = []
			this.items_week.filter( (item) => { if(item.is_use) d.push(item.key) })
			if(d.length > 0){
				t = d.join(',')
			}
			return t
		}
		, list_weeks: function(){
			let t = []

			this.items_weeks.filter( (item) => {
				let d = [
					{ key: 1, name: '월', is_use: false}
					, { key: 2, name: '화', is_use: false}
					, { key: 3, name: '수', is_use: false}
					, { key: 4, name: '목', is_use: false}
					, { key: 5, name: '금', is_use: false}
					, { key: 6, name: '토', is_use: false}
					, { key: 0, name: '일', is_use: false}
				]
				d.filter( ( week ) => {
					if(item.title.indexOf(week.key) > -1){
						week.is_use = true
					}
				})
				t.push(d)
			})
			console.log(t)
			return t
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'get'
					,url: 'management/getHolidayList'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.item_search.tCnt = result.data.tCnt
					this.items_time = result.data.time
					this.items_weeks = result.data.week

				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postSaveTime: async function(item){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSaveTime'
					,data: item.uid ? item : this.item
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postSaveWeek: async function(week){
			console.log(week)
			try{
				this.$bus.$emit('on', true)

				let t = ''
				let d = []
				if(week.uid){
					week.items.filter( (item) => { if(item.is_use) d.push(item.key) })
					if(d.length > 0){
						t = d.join(',')
					}
				}

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSaveWeek'
					,data: {
						data: week.uid ? t : this.use_week
						, is_use: this.item.is_use_week
						, uid: week.uid
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, postSaveHoliday: async function(){
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postSaveHoliday'
					,data: this.item_holiday
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearch: function(page){
			this.item_search.page = page
			this.getData()
		}
		, postDeleteHoliday: async function(item){
			if(!confirm("해당 항목을 삭제하시겠습니까?")){
				return false
			}
			try{
				this.$bus.$emit('on', true)

				const result = await this.$request.init({
					method: 'post'
					,url: 'management/postDeleteHoliday'
					,data: {
						uid: item.uid
					}
				})

				if(result.success){
					this.$bus.$emit('notify', { type: 'success', message: result.message })
					await this.getData()
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e })
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, async created() {
		this.$emit('onLoad', this.program)
		await this.getData()
	}
}
</script>

<style>
.tab h6 { border-right: 1px solid white; cursor: pointer}
</style>